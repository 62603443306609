.main-home {
    display: block;

    .catlist-wrapper {
        margin-top: 47px;

        @media only screen and (max-width:767px) {
            margin-top: 30px;
        }
    }

    .cat-list {
        display: inline-flex;
        align-items: center;
        cursor: pointer;

        @media only screen and (max-width:767px) {
            margin: 10px 0;
        }

        .cat-image {
            width: 80px;
            position: relative;
            margin-right: 19px;

            @media only screen and (max-width:1199px) {
                width: 60px;
                height: 60px;
                margin-right: 15px;
            }

            @media only screen and (max-width:991px) {
                width: 50px;
                height: 50px;
                margin-right: 10px;
            }

            img {
                width: 100%;
            }

        }

        span {
            font-weight: normal;
            font-size: 20px;
            line-height: 1.3;
            flex: 1;

            @media only screen and (max-width:1199px) {
                font-size: 16px;
            }
        }



    }

    .product-list {
        margin: 0;

        p.popular-brands {
            display: block;
            padding: 0px 0 36px 0;
            font-family: "Montserrat", Sans-serif;
            font-size: 36px;
            font-weight: 300;
            text-align: center;
            margin: 0 0 26px 0;
            padding: 0;
            line-height: 1.3;

            @media only screen and (max-width: 1199px) {
                font-size: 28px;
                margin-bottom: 20px;
            }

            @media only screen and (max-width: 767px) {
                font-size: 26px;

            }
        }

        @media only screen and (min-width: 1200px) {
            .row {
                .col-xl-2 {
                    flex: 0 0 20%;
                    max-width: 20%;
                }
            }
        }

        @media (max-width:567px) {
            font-size: 30px;
            line-height: 36px;
            // padding: 0 15px 30px;

        }

        .product-box {
            background: #fff;
            display: block;
            margin-bottom: 35px;
            cursor: pointer;
            box-shadow: 0px 1.8674px 23.3425px rgba(0, 0, 0, 0.1);

            .product-image {
                width: 100%;
                display: block;
                height: 236px;
                position: relative;
                overflow: hidden;

                @media only screen and (max-width:1199px) {
                    height: 160px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .product-info {
                display: block;
                padding: 18px 18px 20px 18px;
                font-family: "Montserrat", Sans-serif;

                p {
                    color: #000000;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    margin: 0 0 2.8px 0;

                    span {
                        // color: #666666;
                        font-weight: 300;
                    }

                }

                p.catname-size {
                    display: block;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13.0718px;
                    line-height: 16px;
                    color: #000;
                    margin: 0 0 16px 0;
                    display: flex;

                    span.elipsis {
                        display: inline-block;
                        width: 50%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-left: 0 !important;
                        text-decoration: initial !important;
                        font-size: 13.0718px !important;
                        line-height: 16px !important;
                        color: #000 !important;
                    }

                    span.ml-auto {
                        display: inline-block;
                        padding-left: 10px !important;
                        border-left: 1px solid #C4C4C4;
                        width: 50% !important;
                        white-space: nowrap !important;
                        font-size: 13.0718px !important;
                        line-height: 16px !important;
                        color: #000 !important;
                        text-decoration: initial !important;
                    }
                }

                p {
                    margin: 0;
                    display: flex;
                    flex-wrap: wrap;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14.9392px;
                    line-height: 18px;
                    color: #000;

                    span {
                        margin-left: 10px;
                        color: #666;
                        text-decoration: line-through;
                        font-weight: 400;
                    }

                }

            }

        }

    }
}

img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

section.product-section {
    padding: 50px 0 60px;
}

.section-title {
    font-family: "Montserrat", Sans-serif;
    font-size: 36px;
    font-weight: 300;
    line-height: 1.3;
    padding: 0px 0 36px 0;
    margin: 0;
}

.n-section-title {
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 1.3;
    color: #000000;
    margin: 0 0 30px 0;

    @media only screen and (max-width:1199px) {
        font-size: 28px;
    }

    strong {
        font-weight: 600;
    }
}

.sub-para {
    color: #000000;
    font-family: "Montserrat", Sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    width: 65%;
    max-width: 65%;
    text-align: center;
    margin: 0 auto 16px;
}

.section {
    font-family: "Montserrat", Sans-serif;

    .pd-r-15 {
        padding-right: 15px
    }

    .pd-l-15 {
        padding-left: 15px
    }
}

.about-section {
    padding: 50px 0 0 0;

    .section-title {
        background: #fff;
        margin: 0 0 0 0;
    }

    .n-about-posh {
        .bg-wrap {
            background: #FFFFFF;
            box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
            border-radius: 40px;
            padding: 53px 45px;
            height: 100%;

            @media only screen and (max-width:1199px) {
                border-radius: 20px;
                padding: 25px;
            }

            .top-div {
                display: flex;
                align-items: center;
                margin: 0 0 15px 0;

                .icon {
                    margin: 0 31px 0 31px;

                    @media only screen and (max-width:1199px) {
                        margin: 0 15px 0 0;
                    }
                }

                h2 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    color: #000000;
                }
            }

            p {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 17px;
                color: #494949;
                margin: 0;
            }
        }
    }
}

section.poshapp-section {
    background: url('../../images/home/app-section-bg.png');
    background-size: contain;
    background-position: center right;
    padding: 124px 0 142px;
    background-repeat: no-repeat;

    @media only screen and (max-width:1199px) {
        background-size: 65% auto;
        padding: 60px 0;
    }

    @media only screen and (max-width:767px) {
        background: transparent;
    }

    .posh-app {
        .desktop-hide {
            display: none;

            @media only screen and (max-width:767px) {
                display: block;
            }
        }

        .app-image {
            img {
                width: 100%;
            }
        }

        .text-content {
            max-width: 553px;

            h2.heading {
                font-style: normal;
                font-weight: 300;
                font-size: 36px;
                line-height: 1.3;
                color: #000000;
                margin: 0 0 20px 0;

                @media only screen and (max-width:1199px) {
                    font-size: 30px;
                }

                @media only screen and (max-width:991px) {
                    font-size: 28px;
                }

                @media only screen and (max-width:767px) {
                    font-size: 22px;
                }

            }

            p {
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 20px;
                color: #000000;
                margin: 0 0 20px 0;
            }

            .barcode {
                display: flex;
                align-items: center;
                

                @media (max-width:567px) {
                    display: none;
                }

                .image {
                    width: 150px;
                    height: 150px;
                    margin-right: 43px;
                }

                p {
                    font-style: normal;
                    // font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    color: #000000;
                    // max-width: 252px;
                }
            }
        }
    }
}

.guidline-section {
    margin: 0px 0;
    padding: 37px 0 76px;

    .section-title {
        padding: 0;
        margin: 0 0 20px 0;
    }

    .sub-para {
        max-width: 100%;
        width: 100%;
    }

    .guideline {
        .nav-tabs {
            border: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: nowrap;

            a {
                padding: 20px 40px 20px 40px;
                background-color: rgba(0, 0, 0, 0);
                border-style: solid;
                border-color: #747474;
                position: relative;
                font-size: 18px;
                font-weight: 400;
                color: #888888;
                border: 0;
                text-align: center;
                border-bottom: 0.5px solid #747474;
                margin: 0 30px;

                @media only screen and (max-width:767px) {
                    padding: 15px 15px;
                    margin: 0 10px;
                }

                &.active {
                    color: #000;

                    &:after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 3px;
                        background: #ff9501;
                        z-index: 2;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: -2px;
                    }
                }
            }
        }

        .tab-content {
            padding: 40px 0;

            .row {
                align-items: center;
            }

            .image {
                img {
                    width: 100%;
                }
            }

            .text-content {
                display: flex;

                .icon-wrap {
                    background: #F2F2F2;
                    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
                    width: 60px;
                    min-width: 60px;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100px;
                    position: relative;
                    margin: 0 39px 0 0;

                    @media only screen and (max-width:767px) {
                        width: 50px;
                        min-width: 50px;
                        height: 50px;
                        margin-right: 15px;
                    }

                    span {
                        background: #FF9501;
                        display: flex;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 15px;
                        color: #FFFFFF;
                        width: 20px;
                        height: 20px;
                        align-items: center;
                        justify-content: center;
                        border-radius: 100px;
                        position: absolute;
                        top: 0;
                        right: 0px;

                        @media only screen and (max-width:767px) {
                            font-size: 10px;
                            top: -5px;
                            right: -5px;
                        }
                    }
                }

                h4 {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 22px;
                    color: #000000;
                    margin: 0 0 5px 0;
                }

                p {
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 17px;
                    color: #494949;
                    margin: 0;
                }

                +.text-content {
                    margin: 30px 0 0 0;
                }
            }
        }
    }
}
.cookie-box {
    position: fixed;
    bottom: 15px;
    left: 15px;
    .toast {
        background: rgba(43,43,43,.8);
        box-shadow: 0px 4px 25px rgba(0,0,0,.1);
        border-radius: 15px;
        padding: 30px;
        width: 100%;
        max-width: 400px;
        color: #fff;
        .toast-body {
            padding: 0;
            span {
                font-size: 15px;
            }
            .border-btn {
                background: #ff9800;
                color: #fff;
                margin: 20px 0 0 0;
                border: 0;
                display: block;
            }
        }
    }
}

@media(max-width:1024px) {
    // .container {
    //     padding: 0 30px !important;
    // }

    section.product-section {
        padding: 40px 0 0px 0;

        .product-list {
            margin: 0;
        }
    }

    .about-section {
        .about-posh {
            margin-top: 38px;
        }
    }

    .section-title {
        font-size: 25px;
        padding: 0px 0 30px 0;
    }

    .guidline-section {
        padding: 40px 0;

        .guideline {
            .tab-content {
                padding: 40px;

                .row {
                    &:nth-child(odd) {
                        .steps {
                            padding-right: 80px;

                            .step {
                                padding: 10px 15px;
                                font-size: 20px;
                                right: -50px;
                                text-align: center;
                            }
                        }
                    }

                    &:nth-child(even) {
                        .steps {
                            padding-left: 80px;

                            .step {
                                padding: 10px 15px;
                                font-size: 20px;
                                left: -50px;
                                text-align: center;
                            }
                        }
                    }

                    &+.row {
                        margin-top: 40px;
                    }
                }

                .step-content {
                    h3.heading {
                        font-size: 22px;
                        line-height: 30px;
                        margin: 0 0 15px 0;
                    }
                }
            }
        }
    }

    .poshapp-section {
        .image {
            img {
                width: 100%;
            }
        }
    }

    .catloge-section {
        padding: 40px 0;

        .container {
            max-width: 100%;
        }

        .catloge-wrap {
            .row {
                .catloge {
                    .image {
                        .big-image {
                            max-width: 320px;
                        }

                        .small-image {
                            max-width: 220px;
                        }
                    }
                }

                &.second {
                    .catloge {
                        .image {
                            .big-image {
                                max-width: 320px;
                            }

                            .small-image {
                                max-width: 220px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media(max-width:991px) {
    .catloge-section {
        .catloge-wrap {
            .row {
                margin: 0;

                .col-lg-6 {
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding: 0;
                }

                .details {
                    padding: 0 !important;
                }

                .catloge {
                    .image {
                        max-width: 450px;
                        margin: 0 auto 80px;
                    }
                }

                +.row {
                    margin-top: 50px;
                }

                &.second {
                    .col-lg-6:nth-child(1) {
                        order: 2;
                    }
                }
            }
        }
    }
}

@media(max-width:767px) {
    // .container {
    //     padding: 0 16px !important;
    // }

    .section-title {
        padding: 0 15px 30px;
        font-size: 20px;
    }

    .about-section {
        .about-posh {
            margin-top: 30px;

            h3 {
                padding: 0 0 15px 0;
                margin: 0;
            }

            p {
                margin: 0 0 15px 0;
            }

            .image {
                margin: 0px 0 20px;
            }

            .left.col-lg-6 {
                order: 2;
                margin: 0 0 30px 0;
            }
        }

        .pd-r-15 {
            padding-right: 0;
        }

        .pd-l-15 {
            padding-left: 0;
        }
    }

    .sub-para {
        max-width: 100%;
        width: 100%;
        padding: 0 16px;
    }

    .guidline-section {
        .guideline {
            .tab-content {
                padding: 20px 20px;

                .row {
                    &:nth-child(odd) {
                        .steps {
                            padding-right: 0;
                        }
                    }

                    &:nth-child(even) {
                        .steps {
                            padding-left: 0;
                        }

                        .col-lg-6:first-child {
                            order: 2;
                        }
                    }

                    .step-content {
                        width: 100%;
                        max-width: 100%;
                        margin: 20px 0 0 0;
                    }

                    .steps {
                        .step {
                            position: initial;
                            transform: none;
                            margin: 20px 0 0 0;
                        }
                    }
                }
            }
        }
    }

    .poshmarket-section {
        padding: 40px 0;

        .why-posh {
            h5 {
                margin: 0 0 15px 0;
            }

            .col-lg-4+.col-md-4 {
                margin-top: 20px;
            }
        }
    }

    section.poshapp-section {
        padding: 40px 0 0 0;

        .barcode {
            margin: 0 0 30px 0;
        }
    }

    .catloge-section {
        .catloge-wrap {
            margin-top: 30px;

            .catloge {
                .details {
                    h3 {
                        margin: 0 0 15px 0;
                    }

                    p {
                        margin: 0 0 15px 0;
                    }
                }

                .image {
                    margin: 0 auto 30px !important;

                    .big-image {
                        max-width: 100% !important;
                    }

                    .small-image {
                        display: none;
                    }
                }
            }
        }
    }

    section.application-link-section {
        padding: 40px 0 40px;

        .application-link {
            form {
                margin: 30px auto 0;
            }
        }
    }

    .row.top-category {
        .col-sm-6 {
            width: 50%;
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
    .cookie-box {
        right: 15px;
        .toast{
            padding: 15px;
        }
    }
    body{
        padding-top: 90px;
    }
}

.orange-txt-clr {
    color: #FF9501 !important;
}
